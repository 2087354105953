import * as contentful from 'contentful'

const languageCodes = {
  // shortCode : fullCode
  en: 'en-US',
  fr: 'fr-FR',
}

export function useContentful(environmentOverride = null) {
  const runtimeConfig = useRuntimeConfig()
  let contentfulEnv = runtimeConfig.public.CTF_ENV;

  if (environmentOverride !== null){
    contentfulEnv = environmentOverride;
  }
  const client = contentful.createClient({
    space: runtimeConfig.public.CTF_SPACE_ID,
    accessToken: runtimeConfig.public.CTF_CDA_ACCESS_TOKEN,
    environment: contentfulEnv,
  });


  const { query } = useRoute()
  const preview = query?.preview === 'true'

  const { locale } = useI18n()
  const fullLocaleCode = languageCodes[locale.value]

  return {
    graphql(query, variables = {}) {
      const url = `https://graphql.contentful.com/content/v1/spaces/${runtimeConfig.public.CTF_SPACE_ID}/environments/${contentfulEnv}`
      const body = {
        query,
        variables: {
          preview,
          locale: fullLocaleCode,
          ...variables,
        },
      }
      return fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${
            preview
              ? runtimeConfig.public.CTF_PREVIEW_ACCESS_TOKEN
              : runtimeConfig.public.CTF_CDA_ACCESS_TOKEN
          }`,
          'Content-Type': 'application/json; charset=utf-8',
        },
        body: JSON.stringify(body),
      })
    },

    cda(entryID) {
      return client.getEntry(entryID)
    },

    asset(assetID) {
      return client.getAsset(assetID)
    },
  }
}
